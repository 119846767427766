import { fetchAuth } from '~/utils/authApi'

export interface Notification {
    id: number
    text: string
    created_at: string
    is_read: boolean
}

export const getNotifications = async (): Promise<Notification[]> => {
    const notifications = await fetchAuth<Notification[]>('/notifications')
    return notifications || []
}

export const clearNotifications = async (): Promise<void> => {
    await fetchAuth('/notifications', {
        method: 'DELETE'
    })
}

export const markNotificationsAsRead = async (ids: number[]): Promise<void> => {
    await fetchAuth(`/notifications/read`, {
        method: 'PATCH',
        body: {
            ids
        }
    })
} 