<template>
    <Popover v-model:open="isOpen">
        <PopoverTrigger as-child>
            <Button class="relative rounded-full" size="icon" variant="ghost">
                <Icon name="ri:notification-3-line" size="18px"/>
                <span
                    v-if="notificationsData.total"
                    class="absolute -top-1 -right-1 h-4 w-4 rounded-full bg-red-500 text-[10px] leading-4 text-white flex items-center justify-center"
                >
                  {{ notificationsData.total }}
                </span>
            </Button>
        </PopoverTrigger>

        <PopoverContent class="w-80">
            <div class="flex items-center justify-between mb-4">
                <h4 class="font-medium">Уведомления</h4>
                <Button
                    v-if="notificationsData?.items?.length"
                    class="text-sm"
                    size="sm"
                    variant="ghost"
                    @click="handleClearNotifications"
                >
                    Очистить
                </Button>
            </div>

            <div class="space-y-2">
                <p
                    v-if="!notificationsData?.items?.length"
                    class="text-sm text-gray-500"
                >
                    У вас нет новых уведомлений
                </p>
                <NuxtLink
                    v-for="notification in notificationsData?.items"
                    v-else
                    :key="notification.id"
                    :to="getNotificationLink(notification)"
                    class="block p-3 bg-slate-50 hover:bg-slate-100 transition-all rounded-lg text-sm"
                >
                    <div v-html="notification.text" class="text-sm"></div>
                    <p class="text-xs text-gray-400">{{ formatDate(notification.created_at) }}</p>
                </NuxtLink>
            </div>
        </PopoverContent>
    </Popover>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue'
import { Popover, PopoverContent, PopoverTrigger } from '~/components/shadcn/ui/popover'
import { Button } from '~/components/shadcn/ui/button'
import { getNotifications, clearNotifications, markNotificationsAsRead } from '~/utils/notificationsApi'

const notificationsData = ref([])
const isOpen = ref(false)
let pollingInterval

const fetchNotifications = async () => {
    try {
        notificationsData.value = await getNotifications()
    } catch (error) {
        console.error('Ошибка при получении уведомлений:', error)
    }
}

const handleClearNotifications = async () => {
    try {
        await clearNotifications()
        notificationsData.value = []
    } catch (error) {
        console.error('Ошибка при очистке уведомлений:', error)
    }
}

const handleMarkAsRead = async () => {
    try {
        const ids = notificationsData.value?.items.filter(notification => !notification.is_viewed).map(notification => notification.id)
        if(!ids.length) return
        await markNotificationsAsRead(ids)
        await fetchNotifications()
    } catch (error) {
        console.error('Ошибка при отметке уведомления как прочитанного:', error)
    }
}

function getNotificationLink(notification) {
    switch(notification.model_type) {
        case 'App\\Models\\OrderEvent':
            return {name: 'order_event', params: {id: notification.model_id}}
        case 'App\\Models\\OrderCamp':
            return {name: 'order_camp', params: {id: notification.model_id}}
        case 'App\\Models\\OrderMasterClass':
            return {name: 'order_master_class', params: {id: notification.model_id}}
        default:
            return '#'
    }
}

watch(isOpen, (newVal) => {
    if(newVal) {
        handleMarkAsRead()
    }
})

onMounted(() => {
    fetchNotifications()
    // Опрашиваем сервер каждые 5 минут
    pollingInterval = setInterval(fetchNotifications, 5 * 60 * 1000)
})

onUnmounted(() => {
    if (pollingInterval) {
        clearInterval(pollingInterval)
    }
})
</script>

<style>
.notification_badge {
    font-weight: 600;
}
</style>

