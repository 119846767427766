<template>
    <Dialog v-model:open="modalIsOpen">
        <DialogTrigger as-child>
            <Button variant="ghost" size="icon" class="rounded-full">
                <Icon name="ri:search-2-line" size="18px" />
            </Button>
        </DialogTrigger>

        <DialogContent class="sm:max-w-2xl">
            <div class="relative">
                <span class="absolute start-0 inset-y-0 flex items-center justify-center px-2">
                    <Icon name="ri:search-2-line" size="20px" class="text-sub" />
                </span>
                <Input
                        v-model="searchQuery"
                        class="h-12 pl-11"
                        placeholder="Поиск по сайту..."
                />
            </div>
            
            <ScrollArea v-if="searchLoadingStatus === 'success'" class="max-h-[500px]">
                <div v-if="searchData?.events?.length"  class="py-2">
                    <h4 class="py-1.5 text-sm font-semibold">События</h4>
                    <div class="grid gap-1">
                        <NuxtLink
                                v-for="result in searchData.events"
                                :key="result.id"
                                :to="{name: 'event', params: {slug: result.slug}}"
                                class="block py-2 px-3 rounded bg-slate-50 hover:bg-slate-100 transition-all">
                            <p class="font-medium">{{ result.name }}</p>
                        </NuxtLink>
                    </div>
                </div>
                <div v-if="searchData?.master_classes?.length"  class="py-2">
                    <h4 class="py-1.5 text-sm font-semibold">Мастер-классы</h4>
                    <div class="grid gap-1">
                        <NuxtLink
                                v-for="result in searchData.master_classes"
                                :key="result.id"
                                :to="{name: 'master_class', params: {slug: result.slug}}"
                                class="block py-2 px-3 rounded bg-slate-50 hover:bg-slate-100 transition-all">
                            <p class="font-medium">{{ result.name }}</p>
                        </NuxtLink>
                    </div>
                </div>
                <div v-if="searchData?.camps?.length"  class="py-2">
                    <h4 class="py-1.5 text-sm font-semibold">Сборы</h4>
                    <div class="grid gap-1">
                        <NuxtLink
                                v-for="result in searchData.camps"
                                :key="result.id"
                                :to="{name: 'camp', params: {slug: result.slug}}"
                                class="block py-2 px-3 rounded bg-slate-50 hover:bg-slate-100 transition-all">
                            <p class="font-medium">{{ result.name }}</p>
                        </NuxtLink>
                    </div>
                </div>
                <div v-if="searchData?.posts?.length"  class="py-2">
                    <h4 class="py-1.5 text-sm font-semibold">Новости</h4>
                    <div class="grid gap-1">
                        <NuxtLink
                                v-for="result in searchData.posts"
                                :key="result.id"
                                :to="{name: 'post', params: {slug: result.slug}}"
                                class="block py-2 px-3 rounded bg-slate-50 hover:bg-slate-100 transition-all">
                            <p class="font-medium">{{ result.name }}</p>
                        </NuxtLink>
                    </div>
                </div>
                <div v-if="searchData?.media?.length"  class="py-2">
                    <h4 class="py-1.5 text-sm font-semibold">Медиа</h4>
                    <div class="grid gap-1">
                        <NuxtLink
                                v-for="result in searchData.media"
                                :key="result.id"
                                :to="{name: 'media_single', params: {slug: result.slug}}"
                                class="block py-2 px-3 rounded bg-slate-50 hover:bg-slate-100 transition-all">
                            <p class="font-medium">{{ result.name }}</p>
                        </NuxtLink>
                    </div>
                </div>
                <div v-if="searchData?.athletes?.length"  class="py-2">
                    <h4 class="py-1.5 text-sm font-semibold">Атлеты</h4>
                    <div class="grid gap-1">
                        <NuxtLink
                                v-for="result in searchData.athletes"
                                :key="result.id"
                                :to="{name: 'athlete', params: {id: result.id}}"
                                class="block py-2 px-3 rounded bg-slate-50 hover:bg-slate-100 transition-all">
                                <div class="flex items-center text-main">
                                    <Avatar :class="'me-2'" :name="result.name" :src="result.avatar"/>
                                    <span class="font-bold text-sm">{{ result.name }}</span>
                                </div>
                        </NuxtLink>
                    </div>
                </div>
            </ScrollArea>

            <div v-else-if="searchLoadingStatus !== 'idle'" class="p-6 text-center text-sub">
                <div v-if="searchLoadingStatus === 'pending'" class="">
                    <Icon name="svg-spinners:3-dots-bounce" size="60" class="text-primary-500" />
                </div>
                <div v-if="searchLoadingStatus === 'success'" class="">
                    Нет результатов по запросу
                </div>
            </div>
            
        </DialogContent>
    </Dialog>
</template>

<script setup>
import { Button } from '~/components/shadcn/ui/button'
import { Dialog, DialogContent, DialogTrigger } from '~/components/shadcn/ui/dialog'
import { ScrollArea } from '~/components/shadcn/ui/scroll-area'
import { Input } from '~/components/shadcn/ui/input'
import Avatar from "~/components/UI/Avatar.vue";
import { useDebounceFn } from '@vueuse/core'

const route = useRoute()
const modalIsOpen = ref(false)
const searchQuery = ref('')
const MIN_SEARCH_LENGTH = 3

const {data: searchData, refresh: fetchSearch, status: searchLoadingStatus} = useAsyncData(
    'search',
    async () => {
        if (searchQuery.value.length < MIN_SEARCH_LENGTH) {
            return null
        }

        return await fetchAuth('search', {
            method: 'GET',
            query: {
                search: searchQuery.value
            }
        }).catch(error => console.error(error))
    },
    {
        immediate: false,
    }
)

// Создаем debounced версию функции обновления поиска
const debouncedSearch = useDebounceFn(() => {
    if (searchQuery.value.length >= MIN_SEARCH_LENGTH) {
        fetchSearch()
    }
}, 500)

// Следим за изменениями поискового запроса
watch(searchQuery, () => {
    debouncedSearch()
})

watch(route, () => {
    modalIsOpen.value = false
})
</script>